import { ROLES } from "@/constants/roles";

export const verifyRole = {
    data(){
        return {
            ROLES,
        }
    },
    methods: {
        checkIfHaveRole(rol, allowed_roles = [], allRoles = false) {
            let allowed_roles_to_check = allRoles ? Object.values(ROLES) : allowed_roles
            if(rol){
                return allowed_roles_to_check.some(item => item == rol)
            }
            return false
        }
    }
  }